
function Footer() {
    
    return (
      <>
  <footer class="w3l-footer-29-main">
          <div class="footer-29 py-5">
              <div class="container py-lg-4">
                  <div class="row footer-top-29">
                      <div class="col-lg-9">
                          <div class="row">
                          <div class="col-md-2 "></div>
                              <div class="col-md-4 col-6 footer-list-29">
                                  <div class="foot">
                                      <h6 class="footer-title-29">Quick Links</h6>
                                    <a href="/About"class="fe" style={{ textDecoration: 'none' }}><font color="white">About</font></a><br></br>
                                   <a href="/Product"class="fe" style={{ textDecoration: 'none' }}><font color="white">Product </font></a><br></br>
                                   <a href="/Services"class="fe" style={{ textDecoration: 'none' }}><font color="white">Services</font> </a><br></br>
                                    <a href="/Contact" class="fe"style={{ textDecoration: 'none' }}><font color="white">Contact Us</font>  </a><br></br>
                           <br></br>         
  <img src="assets/images/flogo.png" class="myimg1" alt=" "/>
                                  </div>
                              </div>
                              <div class="col-md-3  footer-list-29 mt-md-0 mt-4">
                                  <div>
                                      <h6 class="footer-title-29">Product</h6>
                                      <div class="foot">
                                        <a href="/Product" class="fe" style={{ textDecoration: 'none' }}><font color="white">Business Bank Account Open</font></a><br></br>
                                      <a href="/Product"class="fe"  style={{ textDecoration: 'none' }}><font color="white">Business Loan</font></a><br></br>
                                     <a href="/Product" class="fe"style={{ textDecoration: 'none' }}><font color="white"> POS Loan</font></a><br></br>
                                     <a href="/Product" class="fe"style={{ textDecoration: 'none' }}><font color="white">Mortgage</font></a></div></div></div>
                                      <div class="col-md-3  footer-list-29 mt-md-0 mt-4">
                                        <br></br> <br></br>
                                        <div class="foot">
                                      <a href="/Product" class="fe"style={{ textDecoration: 'none' }}><font color="white">Credit Card</font></a><br></br>
                                     <a href="/Product" class="fe"style={{ textDecoration: 'none' }}><font color="white">Personal Loan</font></a><br></br>
</div></div>
                                  
                          
                          </div>
                      </div>
                      <div class="col-lg-3 footer-contact-list mt-lg-0 mt-md-5 mt-4">
                          <h6 class="footer-title-29">Social Media</h6>
                          <div class="main-social-footer-29">
                              <a href="#facebook" class="facebook"><i class="fab fa-facebook-f"></i></a>
                              <a href="#twitter" class="twitter"><i class="fab fa-twitter"></i></a>
                              <a href="#google"><i class="fab fa-google-plus-g"></i></a>
                              <a href="#instagram" class="instagram"><i class="fab fa-instagram"></i></a>
                          </div>
                         
                          <p class="copy-footer-29 mt-4">Moneylink Financing Broker Est<br>
                          </br> Post Box : 87556

  M-320,Hamsa building<br></br>
  Al Karama,Dubai
United Arab Emirates
 <br></br>
  Tel :+971 (4) 88 66 141<br></br> 
  Mob : +971502112413</p>
                                      
                      </div>  
                  </div>
                   <center>
                 
                          <p class="copy-footer-29 mt-4">© 2023 Money Link UAE All Rights Reserved <br></br>Design by <a
                                  href="https://www.infosiltechnolabs.com/"  style={{ textDecoration: 'none' }} target="_blank">
                                  Infosil Technolabs Pvt Ltd</a></p></center>
              </div>
          </div>
      </footer>

  </>
  
    );
   
  }
  
  export default Footer;