import CookieConsent from "react-cookie-consent";

import PhoneInput from 'react-phone-number-input'
import React, { Fragment, useEffect,useState } from "react";
import Footer from '../pages/Footer';
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import {BrowserRouter as Router,Link,NavLink,Route,Routes} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';



function Home() {



    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
    };
    // const imgStyles = {
    //     top:'800px',
    //     height: '2300px',
    //     width: '300px'
    //   };

  return (
    <>
     

  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/images/bnh3.png" class="d-block w-100 banner-image" alt="..."/>
   
      {/* <div class=" banner-content card-img-overlay">

                        <h2 class="mb-lg-4 mb-3 vf  "><font color="white">Transforming Lives<br></br> <font size="10">Solution for Your Financial Strategy</font></font></h2>
                        <p class="mr-5 vd"><font color="white">Moneylink is a licensed financing broker established in the United Arab Emirates and provides financial advices to the clients for all their financial requirements.</font></p>
                       
                   
  </div>              */}
    </div>
  </div>



<section class="about-section py-5">
        <div class="container py-lg-5 py-4">
            <div class="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4" >
                <h3 class="title-style mb-2">End to End Business<font color="#990033">  Consulting</font> </h3>
                <p> Moneylink supports their client in gathering information, obtaining documents and completing the process on their behalf as required.</p>
                    </div>
<section id="advertisers" class="advertisers-service-sec pt-5 pb-5">
  <div class="container">
    <div class="row">
     
    </div>
    <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
      <div class="col">
        <div class="service-card">
        <div class="about-icon mb-4">
                 
                      
                      
                 <i class="fa-solid fa-clock primary-clr-bg"></i>
                 </div>
                 <div class="about-content">
                            <h5 class="mb-2"><a href="#">On Time Service</a></h5>
                            <p>We focused on best services to our customers. Moneylink give  the best advice and secure right financial services  ontime. </p>
                        </div>
        </div>
      </div>
      <div class="col">
        <div class="service-card">
        <div class="about-icon mb-4">
                            
                            <i class="fa-sharp fa-solid fa-people-group primary-clr-bg"></i>
                        </div>
                        <div class="about-content">
                            <h5 class="mb-2"><a href="#">A Team of Experts</a></h5>
                            <p> Moneylink have an experienced team of financial experts,Chartered Accountants, who worked with Multinational Banks</p>
                        </div>
        </div>
      </div>
      <div class="col">
        <div class="service-card">
        <div class="about-icon mb-4">
                            
                            <i class="fa-solid fa-chart-simple primary-clr-bg"></i>
                        </div>
                        <div class="about-content">
                            <h5 class="mb-2"><a href="#">Analyse Your Business</a></h5>
                            <p> Being the best organisation to support all the financial needs of the public and act most efficiently as a facilitator between the financial institutions and banks</p>
                        </div>
        </div>
      </div>
    
    
    </div>
  </div>
</section>

             </div></section>
    <section class="w3l-servicesblock py-5">
        <div class="container pb-lg-5">
            <div class="row pb-xl-5 align-items-center">
                <div class="col-lg-6 position-relative home-block-3-left pb-lg-0 pb-5">
                    <div class="position-relative">
                        <img src="assets/images/home1.jpg" alt="" class="img-fluid radius-image inner-img"/>
                    </div>
                   

                </div>
                <div class="col-xl-5 col-lg-6 offset-xl-1 mt-lg-0 mt-5 pt-lg-0 pt-5">
                <h3 class="title-style mb-2"> We Help You<font color="#990033"> Achieve Your Dreams</font></h3>
                    <p class="mt-lg-4 mt-3 mb-sm-5 mb-4"> Our motive is to offer our customers the best advice,  help them to save time and money by simplifying the entire process in compliance with local regulations.</p>
                    <div class="two-grids mt-md-0 mt-md-5 mt-4">
                    <div class="grids_info mt-5">
                           
                           <i class="fa-sharp fa-solid fa-money-bill-transfer primary-clr-bg"></i>
                           <div class="detail">
                               <h4>Business Banking</h4>
                               <p>Business banking plays a crucial role in managing the financial needs of businesses. It offers a range of services and solutions designed to support the financial operations, growth, and success of businesses.</p>
                               <h6 class="trg" >Business bank account open</h6>
                               <p>Opening a business bank account is an important step for managing your business finances effectively. </p>
                               <h6 class="trg"> Bank loan</h6>
                               <p>Bank loans are a common financing option for businesses and individuals alike. They provide access to funds that can be used for various purposes, such as business expansion, working capital, purchasing assets, or personal needs.</p>
                           </div>
                       </div><br></br>
                        <div class="grids_info">
                            
                            <i class="fa-solid fa-building-columns primary-clr-bg"></i>
                            <div class="detail">
                                <h4>Personal Banking</h4>
                                <p>Personal banking encompasses the products and services the banks provide to individuals and families to meet their primary financial needs.</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4" >
                <h3 class="title-style mb-2">Trusted<font color="#990033"> Financial Partner</font></h3>
                <p>As a team of dedicated professionals, we make it easy to get the right financial products from the right financial institutions.</p>
            </div>
    <div class="bgm9">
        <div class="container py-md-5 py-4">
           <br></br> <br></br> <br></br> <br></br> <br></br>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                <div class="flip-card">
    <div class="flip-card-inner">
        <div class="flip-card-front">
        <div class="box-wrap">
                
        
        <h4><a href="#url"><font color="#fff">Business Bank Account Open</font></a></h4>
                        <div class="c1">Are you a business owner seeking a streamlined financial experience that empowers your business to thrive? Look no further! Opening a dedicated business bank account can revolutionize the way you manage your finances, provide essential financial tools, and pave the way for sustainable growth.opening a business bank account is a must for your company.</div>
                        {/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div> 
                       
        <div class="flip-card-back">
        <div class="box-wrap">
                        {/* <div class="icon">
                            
                            <i class="fa-regular fa-credit-card blue-clr-bg"></i>
                        </div>
                        <h4 class="number">01</h4> */}
                        <h4><a href="#url"><font color="#fff">Business Bank Account Open</font></a></h4>
                        <div class="c1">Are you a business owner seeking a streamlined financial experience that empowers your business to thrive? Look no further! Opening a dedicated business bank account can revolutionize the way you manage your finances, provide essential financial tools, and pave the way for sustainable growth.opening a business bank account is a must for your company.</div>
                        {/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>  
      </div></div></div>

 
                <div class="col-lg-4 col-md-6 mt-md-0 mt-4">

                <div class="flip-card">
    <div class="flip-card-inner">
        <div class="flip-card-front">
        <div class="box-wrap">
                    
                        <h4><a href="#url"><font color="#fff">Business Loan</font></a></h4>
                        <div class="c1">Are you ready to take your business to new heights? Whether you're looking to expand operations, invest in new equipment, or seize exciting opportunities, a business loan can provide the financial boost you need. your business's growth and propel you towards success.</div>
                        <br></br><br></br><br></br><br></br><br></br> {/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
        <div class="flip-card-back">
        <div class="box-wrap">
                  
        <h4><a href="#url"><font color="#fff">Business Loan</font></a></h4>
        <div class="c1">Are you ready to take your business to new heights? Whether you're looking to expand operations, invest in new equipment, or seize exciting opportunities, a business loan can provide the financial boost you need. your business's growth and propel you towards success.</div>
        <br></br><br></br><br></br><br></br><br></br>  {/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
      </div></div></div>
       


                <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">

                <div class="flip-card">
    <div class="flip-card-inner">
        <div class="flip-card-front">
        <div class="box-wrap">
                     
        <h4><a href="#url"><font color="#fff">POS Loan</font></a></h4>
        <div class="c1"> Are you a business owner looking to upgrade your point-of-sale system or enhance your customer experience? A point-of-sale (POS) loan is a powerful financing option that can help you achieve your goals without straining your cash flow. POS loan is the perfect solution to propel your business forward.</div>
        <br></br><br></br><br></br><br></br> &nbsp;{/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
        <div class="flip-card-back">
        <div class="box-wrap">
                     
        <h4><a href="#url"><font color="#fff">POS Loan</font></a></h4>
        <div class="c1"> Are you a business owner looking to upgrade your point-of-sale system or enhance your customer experience? A point-of-sale (POS) loan is a powerful financing option that can help you achieve your goals without straining your cash flow. POS loan is the perfect solution to propel your business forward.</div>
        <br></br><br></br><br></br><br></br> &nbsp;  {/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
      </div></div></div>


                <div class="col-lg-4 col-md-6 ">

                <div class="flip-card">
    <div class="flip-card-inner">
        <div class="flip-card-front">
        <div class="box-wrap">
                      
                        <h4><a href="#url"><font color="#fff">Mortgage Loan</font></a></h4>
                        <div class="c1">Are you tired of renting and ready to embark on the journey of homeownership? A mortgage is a powerful financial tool that can make your dreams of owning a home a reality. mortgage is a wise investment for your future and a path towards financial stability</div>
                        <br></br> <br></br> <br></br>    {/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
        <div class="flip-card-back">
        <div class="box-wrap">
                      
        <h4><a href="#url"><font color="#fff">Mortgage Loan</font></a></h4>
        <div class="c1">Are you tired of renting and ready to embark on the journey of homeownership? A mortgage is a powerful financial tool that can make your dreams of owning a home a reality. mortgage is a wise investment for your future and a path towards financial stability</div>
        <br></br> <br></br> <br></br>      {/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
      </div></div></div>
     
                <div class="col-lg-4 col-md-6 ">
                <div class="flip-card">
    <div class="flip-card-inner">
        <div class="flip-card-front">
        <div class="box-wrap">
                      
                        <h4><a href="#url"><font color="#fff">Credit Card</font></a></h4>
                        <div class="c1">Money link enables a unique contactless features and a card design that enables you to make fast,convenient and secure every day .Our Flexible option to pay through EMI purchase, Reward point or credit. We are providing fuel charger waiver and entertainment offers. </div>
                        <br></br><br></br> &nbsp;{/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
        <div class="flip-card-back">
        <div class="box-wrap">
                      
        <h4><a href="#url"><font color="#fff">Credit Card</font></a></h4>
        <div class="c1">Money link enables a unique contactless features and a card design that enables you to make fast,convenient and secure every day .Our Flexible option to pay through EMI purchase, Reward point or credit. We are providing fuel charger waiver and entertainment offers. </div>
        <br></br><br></br> &nbsp;{/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
      </div></div></div>

                   
                <div class="col-lg-4 col-md-6 ">
                <div class="flip-card">
    <div class="flip-card-inner">
        <div class="flip-card-front">
        <div class="box-wrap">
                       
                        <h4><a href="#url"><font color="#fff">Personal Loan</font></a></h4>
                        <div class="c1">Money link offers personal loans for education, wedding, business etc. ,at an attractive interest rate and flexible repayment tenures. Anyone can apply through online. Money link also offers a special top up balance transfer feature too.</div>
                        <br></br> <br></br>  <br></br> <br></br> {/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
        <div class="flip-card-back">
        <div class="box-wrap">
                      
                        <h4><a href="#url"><font color="#fff">Personal Loan</font></a></h4>
                        <div class="c1">Money link offers personal loans for education, wedding, business etc. ,at an attractive interest rate and flexible repayment tenures. Anyone can apply through online. Money link also offers a special top up balance transfer feature too.</div>
                        <br></br> <br></br>  <br></br> <br></br>  {/* <a href="#read" class="read">Read more</a> */}
                    </div>
                </div>
      </div></div></div>
</div>

                  
            </div>
        </div>
       
 











   



    <section class="w3l-ab-section py-5">
        <div class="container py-md-5 py-4">
            <div class="row py-lg-4">
                <div class="col-lg-6 section-width align-self">
                    <h3 class="title-style pr-xl-5">Experience Our <font color="#990033">Loan And Cards </font> Facility</h3>
                    <p class="mt-lg-4 mt-3 pb-3">Moneylink recognize the needs of customers and provides free preliminary consultations on their current financial situation and points them towards the right solutions</p>
                    <a href="/Services" class="btn btn-style mt-4">Our Services</a>
                </div>
                <div class="col-lg-6 history-info mt-5 pt-lg-0 pt-5">
                    <div class="position-relative img-border">
                        <img src="assets/images/g.png" class="img-fluid inner-img video-popup-image" alt="video-popup"/>

                     
                        
                       
                    </div>
                </div>
            </div>
        </div>
    </section>

   
        














    <br></br><br></br><br></br><br></br>
    <div class="bg7" id="ok"><br></br>
   <center> <h3 class="title-style pr-xl-5"><font color="#fff">How We Can Help</font> </h3>
   <p> <font color="white">Will give your details and we'll call you 24hrs to review your sitution</font></p></center>
    <div class="w3l-contact-info py-5" id="contact">
        <div class="container ">
        <Form action="https://formsubmit.co/info@moneylinkuae.com" method="post" class="signin-form " noValidate validated={validated} onSubmit={handleSubmit}>

            <div class="row mt-5 pt-md-5">
            <div class="col-lg-6 form-inner-cont order-lg-2 order-1 mb-lg-0 mb-5">
          
            <div class="form-input">
    <label for="validationCustom03" class="form-label"></label>
    <input type="text" class="form-control" name="Appointment_f_name" id="validationCustom03" placeholder="First name" required/>
    <div class="invalid-feedback">
      Please provide a valid name
    </div>
</div>



        






                                
        <div class="form-input">
    <label for="validationCustom04" class="form-label"></label>
    <input type="text" class="form-control" id="validationCustom04" name="Appointment_l_name"  placeholder="Last name" required/>
    <div class="invalid-feedback">
      Please provide a valid name.
    </div>
</div>
                       
                   
                
                </div>
                <div class="col-lg-6 form-inner-cont order-lg-2 order-1 mb-lg-0 mb-5">
                  
                <div class="form-input">
    <label for="validationCustom05" class="form-label"></label>
    <input type="email" class="form-control" id="validationCustom05" name="Appointment_email"  placeholder="Email" required/>
    <div class="invalid-feedback">
      Please provide a valid email.
    </div>
</div>
<div class="form-input">
    <label for="validationCustom05" class="form-label"></label>
    <input type="number" class="form-control" id="validationCustom05" name="Appointment_phone"  placeholder="Phone" required/>
    <div class="invalid-feedback">
      Please provide a valid Number.
    </div>
</div>
                        
                       
                     
                       
                       
                     
                </div>
                
            </div><br></br>
            <div class="text-start">
                          <center> <button type="submit" class="btn btn-style btn-primary">Book An Appointment</button></center> 
                        </div>
                        </Form>
           
        </div>
       
    </div>
    </div>




    
    <CookieConsent
  location="bottom"
  buttonText="I Accept"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#990033" }}
  buttonStyle={{ background: "#ffff", fontSize: "20px" }}
  expires={150}
>
We use cookies to improve your journey and to personalize your web experience. {" "}
   By continuing to use this site, you are accepting the Moneylinkuae cookie policy.
</CookieConsent>
    
    <Footer></Footer>


</>

  );
 
}

export default Home;