
import Carousel from 'react-bootstrap/Carousel';
import React, { Fragment, useEffect,useState } from "react";
import Footer from '../pages/Footer';
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import {BrowserRouter as Router,Link,NavLink,Route,Routes} from "react-router-dom";

   
function Product() {
    const [isOpen, setOpen] = useState(false)
    return (
      <>


<Carousel>
      <Carousel.Item>
      <img
          className="caroimg"
          src="assets/images/pro.png"
          alt="Second slide"
        />
        <Carousel.Caption>
        <div class="banner-content w3l-banner4 py-5">
        <div class="container pt-5 pb-md-4">
            <div class="row align-items-center py-4">
                <div class="pl1">
                
                    <h3 class="mb-lg-4 mb-3 title ">Secure Your Financial <font color="#990033">Future with Our Top-notch Financial  Products<span></span></font></h3>
                   
                </div>
               
            </div>
        </div>
    </div>   
        </Carousel.Caption>
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="caroimg"
          src="assets/images/ca2.jpg"
          alt="Second slide"
        />
   <Carousel.Caption>
        <div class="banner-content w3l-banner5 py-5">
        <div class="container pt-5 pb-md-4">
            <div class="row align-items-center py-4">
                <div class="pl2">
                
                    <h3 class="mb-lg-4 mb-3 title ">Maximize Your Wealth Potential  <font color="#990033">with Our Comprehensive Financial Product Offerings<span> </span></font></h3>
                   
                </div>
               
            </div>
        </div>
    </div>   
        </Carousel.Caption>
       
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="caroimg"
          src="assets/images/ca1.jpg"
          alt="Second slide"
        />
 <Carousel.Caption>
        <div class="banner-content w3l-banner4 py-5">
        <div class="container pt-5 pb-md-4">
            <div class="row align-items-center py-4">
                <div class="pl1">
                
                    <h3 class="mb-lg-4 mb-3 title ">Trusted Financial  <font color="#990033">Solutions<span> </span></font></h3>
                   
                </div>
               
            </div>
        </div>
    </div>   
        </Carousel.Caption>
       
      </Carousel.Item>
    </Carousel>
    <section class="w3l-team py-5" >
        <div class="container py-md-5 py-4 "id="cr">
            <div class="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4">
                <h3 class="title-style mb-2">Choose By<font color="#990033"> Category!</font>  </h3>
                <p>We facilitate for your financial needs and assist between you and the banks or other lending institutions for securing business loans, mortgage Loans, personal loans, Auto Loans and Credit cards and bank facilities etc.,</p>
            </div>
            <div class="row text-center" >
                <div class="col-md-4 col-6">
                    <div class="team-grids text-center"id="team">
                        <img src="assets/images/atm.jpg" class="img-fluid" alt=""/>
                        <div class="team-info">
                            <div class="caption">
                              
                     
                    </div>

                        </div>
                    </div>
                    <h4>Business Bank Account Open</h4>
                 
                </div>
                <div class="col-md-4 col-6">
                    <div class="team-grids text-center">
                        <img src="assets/images/l1.jpg" class="img-fluid" alt=""/>
                        <div class="team-info">
                            <div class="caption">
                              
                    
                    </div>

                            
                        </div>
                    </div>
                    <h4>Business Loan </h4>
                 
                </div>
                <div class="col-md-4 col-6 mt-md-0 mt-sm-5 mt-5">
                    <div class="team-grids text-center">
                        <img src="assets/images/pl.jpg" class="img-fluid" alt=""/>
                        <div class="team-info">
                            <div class="caption">
                               
                 
                    </div>

                             
                        </div>
                    </div>
                    <h4> POS Loan</h4>
                
                </div>
         
            </div>
        </div>
        

        <div class="container py-md-5 py-4 "id="ok">
           
            <div class="row text-center">


            <div class="col-md-4 col-6 mt-md-0 mt-sm-5 mt-5" >
                    <div class="team-grids text-center">
                        <img src="assets/images/gl.jpg" class="img-fluid" alt=""/>
                        <div class="team-info">
                            <div class="caption">
                               
            
                    </div>

                          
                        </div>
                    </div>
                    <h4>Mortgage</h4>
                  
                </div>
                <div class="col-md-4 col-6">
                    <div class="team-grids text-center" >
                        <img src="assets/images/atm1.jpg" class="img-fluid" alt=""/>
                        <div class="team-info">
                            <div class="caption">
                              
                                  
                                <div class="mt-md-5 mt-4 mb-lg-0 mb-4">
                
                    </div>


                             
                             
                            </div>
                        </div>
                    </div>
                    <h4>Credit Card</h4>
                   
                </div>
                <div class="col-md-4 col-6">
                    <div class="team-grids text-center">
                        <img src="assets/images/pk.jpg" class="img-fluid" alt=""/>
                        <div class="team-info">
                            <div class="caption">
                               
                    </div>

                              
                        </div>
                    </div>
                    <h4>Personal Loan</h4>
                  
                </div>
             

















            
             
            </div> <br></br><br></br>    <center>   <a class="btn btn-style" href="/Contact">Get Started</a></center>
        </div>
        
    </section>
   
    <div class="middle py-5">
        <div class="container py-lg-5 py-4">
            <div class="welcome-left text-center py-lg-4 m-auto">
                <h5></h5>
                <h3 class="mt-2"><font color="#990033">Features And Benefits</font></h3>
                {/* <a href="#started" class="btn btn-style btn-white mt-sm-5 mt-4">Get started</a> */}
            </div>
        </div>
    </div>
  
    <section class="w3l-ab-section py-5">
        <div class="container py-md-5 py-4">
            <div class="row py-lg-4">
                <div class="col-lg-6 section-width align-self">
                    <h3 class="title-style pr-xl-5">Choose Your Life Style <br></br>
                   <font color="#990033"> We Make It Easy For You</font></h3>
                    <p class="mt-lg-4 mt-3 pb-3">An emergency fund is your own pool of money that you can tap into when you are caught in a financial difficulty.</p>
                    <a href="/Services" class="btn btn-style mt-4">Our Services</a>
                </div>
                <div class="col-lg-6 history-info mt-5 pt-lg-0 pt-5">
                    <div class="position-relative img-border">
                        <img src="assets/images/aa.jpg" class="img-fluid video-popup-image" alt="video-popup"/>

                      </div></div></div></div></section>
                      <div class="bg7" id="ok"><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
    </div>
  <Footer></Footer>
  
  
  
  </>
  
    );
   
  }
  
  export default Product;