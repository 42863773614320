import Footer from '../pages/Footer';
import Carousel from 'react-bootstrap/Carousel';
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function Services() {
    
    return (
        <>


<Carousel>
      <Carousel.Item>
      <img
          className="caroimg"
          src="assets/images/ss1.jpg"
          alt="Second slide"
        />
       <Carousel.Caption>
        <div class="banner-content w3l-banner3 py-5">
        <div class="container pt-5 pb-md-4">
            <div class="row align-items-center py-4">
                <div class="ml2">
                
                    <h3 class="mb-lg-4 mb-3 title "><font color="#990033">Unclock the Power of Business Banking :<br></br> Open an Account Today!</font></h3>
                   
                </div>
               
            </div>
        </div>
    </div>   
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="caroimg"
          src="assets/images/ss2.jpg"
          alt="Second slide"
        />

<Carousel.Caption>
        <div class="banner-content w3l-banner2 py-5">
        <div class="container pt-5 pb-md-4">
            <div class="row align-items-center py-4">
                <div class="ml1">
                
                    <h3 class="mb-lg-4 mb-3 title "><font color="#990033">Unlock Your Business Potential With a Point-Of-Sale (POS) Loan<span></span></font></h3>
                   
                </div>
               
            </div>
        </div>
    </div>   
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="caroimg"
          src="assets/images/ss3.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
        <div class="banner-content w3l-banner2 py-5">
        <div class="container pt-5 pb-md-4">
            <div class="row align-items-center py-4">
                <div class="ml1">
                
                    <h3 class="mb-lg-4 mb-3 title "><font color="#990033">Discover the Benefits of Home Owner-ship: Secure Your Future With a Mortgage.    </font></h3>
                   
                </div>
               
            </div>
        </div>
    </div>   
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>


    <section class="w3l-services py-5" id="services">
        <div class="container py-md-5 py-4">
            <div class="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4" >
                <h3 class="title-style mb-2">What's Services We Are Offering to Our Customers</h3>
                <p>We facilitate for your financial needs and assist between you and the banks or other lending institutions for securing business loans, mortgage Loans, personal loans, Auto Loans and Credit cards and bank facilities etc.</p>
            </div>
   
               <center><h3 class="title-style mb-2">Our Features</h3></center> 
       <br></br><br></br>
          
            <div class="row">
                
                <div class="col-lg-4 col-md-6">
                  
                    <div class="box-wrap1">
                       <center>
                        <h4><a href="#url"><font color="#fff">Fast Disbursal</font>
                        </a></h4></center>
                       
                        <p><font color="white"> Fast  approval
quick disbursal any time any where</font>

</p><br></br><br></br><br></br><br></br>
                       
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
                    <div class="box-wrap1">
                      <center>
                        <h4><a href="#url"><font color="#fff">Safe & Secure</font></a></h4></center>
                        <p><font color="white">Do it the safe way 
do it the Right way
do it every day
or
safety first last and always </font></p><br></br><br></br>
                        <br></br>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
                    <div class="box-wrap1">
                     <center>
                        <h4><a href="#url"><font color="#fff">Personalised</font></a></h4></center>
                        <p><font color="white">Financial planning and discipline is key to one,s financial freedom</font></p>
                        <br></br><br></br><br></br><br></br>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                    <div class="box-wrap1">
                       <center>
                        <h4><a href="#url"><font color="#fff">Decison Maker</font></a></h4></center>
                        <p><font color="white"> Some time its  the smallest decision  that can change your life forever</font></p>
                        <br></br><br></br><br></br>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                    <div class="box-wrap1">
                  <center>
                        <h4><a href="#url"><font color="#fff">Customer Oriented</font></a></h4></center>
                        <p><font color="white">Orientation towards customer retention managing customer profitability customer information gathering 
customer targetting. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  </font></p>
                        
&nbsp;</div>
                </div>
                <div class="col-lg-4 col-md-6 mt-4">
                    <div class="box-wrap1">
                     <center>
                        <h4><a href="#url"><font color="#fff">Report Analysis</font></a></h4></center>
                        <p><font color="white"> What get measured get managed. </font></p>
                       <br></br><br></br><br></br><br></br> &nbsp; &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </section>
   
    <div class="middle py-5 " id="faq">
        <div class="container py-lg-5 py-4">
            <div class="welcome-left text-center py-lg-4 m-auto">
                <h5>Any plan to start ....</h5>
                <h3 class="mt-2">Our Experts always ready to work with you.</h3>
                {/* <a href="#started" class="btn btn-style btn-white mt-sm-5 mt-4">Get started</a> */}
            </div>
        </div>
    </div>
   
    <div class="w3l-faq-block py-5" id="faq">
        <div class="container py-lg-5">
            <div class="row">
                <div class="col-lg-7">
                    <section class="w3l-faq" id="faq">
                      
                        {/* <div class="faq-page mt-4">
                        <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            
          <Typography>What types of financial services do you offer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          This question is a good starting point for potential customers who want to learn more about the financial services you provide. Be sure to provide a clear and concise overview of the services you offer, such as loans, credit cards, investment accounts, and insurance products.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do I apply for a loan or credit card?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Potential customers may want to know how to apply for your financial services. Provide clear instructions on how to apply, including any necessary documentation or eligibility requirements.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>What are the interest rates and fees for your financial services?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Customers are often interested in the cost of financial services, including interest rates and fees. Be transparent and provide detailed information on the cost of each service, including any applicable fees, penalties, or charges.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>How quickly can I access funds or receive payouts?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Speed is an important factor for many customers, especially when it comes to accessing funds or receiving payouts. Provide information on how quickly customers can expect to receive funds, such as same-day or next-day disbursal options. 
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
                        </div>
                    </section>
                </div>
                <div class="col-lg-5 mt-lg-0 mt-sm-5 mt-4">
                    <div class="banner-form-w3">

                        <form action="#" method="post">
                            <h3 class="title-style">Request a <span>Quote</span></h3>
                            <p class="mt-3 text-dark">Fill all information details to consult with us to get sevices
                                from us</p>
                            <div class="form-style-w3ls mt-4">
                                <input placeholder="Your Name" name="name" type="text" required=""/>
                                <input placeholder="Your Email" name="email" type="email" required=""/>
                                <input placeholder="Phone Number" name="phone" type="text" required=""/>
                                <button class="btn btn-style w-100"> Get a Quote</button>
                            </div>
                        </form>
                     
                    </div>
                </div> */}
                </section></div>
            </div>
        </div>
    </div>
    <div class="bg7" id="ok"><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
    </div>
<Footer></Footer>




        </>

  );
 
}

export default Services;