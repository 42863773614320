import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel'
import Footer from '../pages/Footer';
import React, { Fragment, useEffect,useState } from "react";
function Contact() {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
    };
    return (
      <>

<Carousel>
      <Carousel.Item>
      <img
          className="caroimg9"
          src="assets/images/ch.jpg"
          alt="Second slide"
        />
       
      </Carousel.Item>
      </Carousel><br></br><br></br>
      <center><h3 class="title-style mb-2"><font color="#990033">  Contact Us</font> </h3></center>
<div class="w3l-contact-info py-5" id="contact">
<div class="container py-md-5 py-4">
    <div class="align-self">
        <div class="contact-infos">
            <div class="single-contact-infos">
                <div class="icon-box"><i class="fas fa-map-marker-alt primary-clr-bg"></i></div>
                <div class="text-box">
                    <h3 class="mb-3">Address info</h3>
                    Post Box : 87556<br></br>
 M-320,Hamsa Building,<br></br>
   AlKarama, Dubai,<br></br>
  United Arab Emirates



                </div>
            </div>
            <div class="single-contact-infos">
                <div class="icon-box"><i class="fa-solid fa-envelope primary-clr-bg"></i></div>
                <div class="text-box">
                    <h3 class="mb-3">Email</h3>
                    <p class=""> 
                    info@moneylinkuae.com</p>

                   
                    
                </div>
                <div class="icon-box"><i class="fa-solid fa-phone primary-clr-bg"></i></div>
                <div class="text-box">
                    <h3 class="mb-3">Phone</h3>
                    <p class=""> 
                    <a href="tel:+971502112413"><font color="#000">+971502112413</font></a><br></br>
                    <a href="tel:+971(4)8866141"><font color="#000">+971 (4) 88 66 141</font></a></p>

                   
                    
                </div>
            </div>
            <div class="single-contact-infos">
                <div class="icon-box"><i class="fa-solid fa-location-dot primary-clr-bg"></i></div>
                <div class="text-box">
                    <h3 class="mb-3">Location </h3>
                    <div class='map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.5763000636202!2d55.305916774567976!3d25.25119202951311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f432958555551%3A0x19d59190950ee7aa!2sUnique%20World%20Business%20Center%20LLC!5e0!3m2!1sen!2sin!4v1681451165176!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br></br>   <br></br>  <br></br>   <br></br> <br></br>   <br></br> <br></br>   <br></br>  
    <div class="row mt-5 pt-md-5">
    <div class="col-md-6 mt-md-0 mt-5 mb-lg-0 mb-5 right-banner-2 position-relative">
                    <div class="sub-banner-image">
                        <img src="assets/images/co.png"
                            class="img-fluid radius-image-full position-relative" alt=" "/>
                    </div>
                  
                
                </div>
        <div class="col-lg-6 form-inner-cont order-lg-2 order-1 mb-lg-0 mb-5">
        <Form action="https://formsubmit.co/cc60e5944bc968aaea0fa21f1821f0db" method="post" class="signin-form " noValidate validated={validated} onSubmit={handleSubmit}>
            <div class="form-input">
    <label for="validationCustom01" class="form-label"></label>
    <input type="text" class="form-control" id="validationCustom01" name="Contact_name"  placeholder="Your name" required/>
    <div class="invalid-feedback">
      Please provide a valid Name.
    </div>
</div>
<div class="form-input">
    <label for="validationCustom02" class="form-label"></label>
    <input type="email" class="form-control" id="validationCustom02" name="Contact_email"  placeholder="Your email" required/>
    <div class="invalid-feedback">
      Please provide a valid Email.
    </div>
</div>
             
             
  <div class="mb-3">
    <label for="validationTextarea" class="form-label"></label>
    <textarea class="form-control is-invalid" id="validationTextarea" name="Contact_message" placeholder="Message" required></textarea>
    <div class="invalid-feedback">
  
    </div>
  </div>




                <div class="text-right">
                    <button type="submit" class="btn btn-style btn-primary">Submit</button>
                </div>
          </Form>
        </div>
    </div>
</div>
</div>
<div class="bg7" id="ok"><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
    </div>
<Footer></Footer>
</>

  );
 
}

export default Contact;