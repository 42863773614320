
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Footer from './pages/Footer';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Product from './pages/Product';
import Dropdown from 'react-bootstrap/Dropdown';

import {BrowserRouter as Router,Link,NavLink,Route,Routes} from "react-router-dom";

function App() {
  
  return (
    <Router>
        
    <div className="App">
      
    
    
    
       <header id="site-header" class="fixed-top" >
       <div class="navo ph ">
       <div class="row">
        <div class="col-sm-1"></div>
        <div class='col-sm-3'>
        {/* &nbsp;&nbsp; <i class="fa-solid fa-phone"></i>&nbsp;<a href="tel:+971502112413"><font color="#990033">+971502112413</font></a> */}
        </div>
     <div class="col-sm-4"></div>
     <div class="col-sm-4">
     <font color="#990033"> <i class="fa-solid fa-envelope"></i>&nbsp;<a href = "mailto: info@moneylinkuae.com"><font color="#990033">info@moneylinkuae.com</font></a>&nbsp;&nbsp; <i class="fa-solid fa-phone"></i>&nbsp;<a href="tel:+971502112413"><font color="#990033">+971502112413</font></a>  </font>
     </div>
    </div>
         </div>  
        <div class="">
      
            <nav class="navbar navbar-expand-lg stroke px-0">
           
                    <a class="navbar-brand " href="/">
                    <img src="assets/images/logo123.png" class="myimg" alt=" "/>
                    </a>
               
                <button class="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse"
                    data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
                  
                   <a href=''> <span class="navbar-toggler-icon fa icon-close fa-times"></span></a>
                </button>  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
               
                    <ul class="navbar-nav ml-lg-auto">
                        <li class="nav-item ">
                        <a href='/'  class="nav-link " >Home</a>
                        </li>
                        
                        <li class="nav-item ">
                        <a href='/About'  class="nav-link " >About Us</a>
                        </li>
                      
                        <li class="nav-item ">
                        <a class="nav-link " href="/Product">Product</a>
    <ul class='anu'>
      <li><a  class="nav-link gh  "href="/Product#team">Business Bank Account Open&nbsp;</a></li>
      <li><a class="nav-link gh "href="/Product#team">Business Loan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</a></li>
      <li><a  class="nav-link gh "href="/Product#team">POS Loan&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</a></li>
      <li><a class="nav-link gh "href="/Product#team">Mortgage&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</a></li>
      <li><a  class="nav-link gh "href="/Product#ok">Credit Card</a></li>
      <li><a  class="nav-link gh "href="/Product#ok">Personal Loan</a></li>
  
    </ul></li>

 
                        <li class="nav-item">
                        <a href="/Services" class="nav-link">Services</a>
                        </li>
                        <li class="nav-item">
                        < a href="/Contact" class="nav-link" >Contact Us</a>
                        </li>
 
                     




                        <div class=" ml-xl-3 ml-1 mr-xl-1">
                        <a class="btn btn-style" href="/#ok">Book An Appointment</a>
                        </div>
                        </ul>
                        </div>
               
          
                
                      
            </nav>
        </div>
     
    </header>
   
   </div>
   <Routes><Route exact path={"/"} element={<Home></Home>}></Route>  
   <Route path={"/About"} element={<About></About>}></Route>
   <Route path={"/Footer"} element={<Footer></Footer>}></Route>
   <Route path={"/Contact"} element={<Contact></Contact>}></Route>
   <Route path={"/Product"} element={<Product></Product>}></Route>
   <Route path={"/Services"} element={<Services></Services>}></Route>
   </Routes> 

</Router>

  );
}

export default App;
