
import Footer from '../pages/Footer';
function About() {
   
  return (
    <>
   <section><br></br>
   <div class="container pt-5 pb-md-4">
            <div class="row align-items-center py-4">
                <div class="col-md-6 pr-lg-5">
                <h3 class="title-style"><font color="#990033"> Find Ideas</font>  Take A  Step To Achieve Your Target</h3>
                    <p class="mr-5 "> We offer a wide range of secured and unsecured loans to our customers. We provide a one-stop-shop for all requirements, be it loans, investments or protection</p>
                    <div class="mt-md-5 mt-4 mb-lg-0 mb-4">
                        <a class="btn btn-style" href="about.html">Get Started</a>
                    </div>
                </div>
                <div class="col-md-6 mt-md-0 mt-5 mb-lg-0 mb-5 right-banner-2 position-relative">
                    <div class="sub-banner-image">
                        <img src="assets/images/img.png"
                            class="img-fluid inner-img radius-image-full position-relative" alt=" "/>
                    </div>
                  
                
                </div>
            </div>
        </div>
        </section>
    <section class="w3l-servicesblock py-5">
        <div class="container py-lg-5 py-4">
            <div class="row pt-xl-5 pt-lg-0 pt-5 pb-xl-4 align-items-center">
                <div class="col-lg-6 position-relative home-block-3-left">
                    <div class="position-relative">
                        <img src="assets/images/n.png" alt="" class="img-fluid inner-img radius-image"/>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-6 offset-xl-1 mt-lg-0 mt-5">
                    <h3 class="title-style">   Choose Better One
 Welcome to<font color="#990033"> Money Link!</font> </h3>
                    <p class="mt-lg-4 mt-3 mb-sm-5 mb-4">Moneylink is your one-stop destination to search for the best Home Loans, Personal Loans and Credit Cards in India. </p>
                    <div class="two-grids mt-md-0 mt-md-5 mt-4">
                        <div class="grids_info">
                            <i class="fas fa-user-clock primary-clr-bg"></i>
                            <div class="detail">
                                <h4>Our Mission</h4>
                                <p>To deliver innovative products and services to cater to the growing needs of an Aspirational India, serving both Individual & Business Clients</p>
                            </div>
                        </div>
                        <div class="grids_info mt-sm-5 mt-4">
                            <i class="fas fa-laptop-house primary-clr-bg"></i>
                            <div class="detail">
                                <h4>Our Vision</h4>
                                <p>We ensure that the highest standard of professional conduct is embedded in every corner of the Organization. It defines how we go about our business, treat our people, customers and stakeholders</p>
                            </div>
                        </div>
                        <div class="grids_info mt-sm-5 mt-4">
                        <i class="fa-solid fa-hourglass-start primary-clr-bg"></i>
                           
                            <div class="detail">
                                <h4>Our Values</h4>
                                <p>As we continue to increase our reach in every corner of the country, we value those who work with us and the contributions that they make to our business.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="w3_stats py-5" id="stats">
        <div class="container py-md-5 py-4">
            <div class="w3-stats text-center py-md-4">
                <div class="row mt-4">
                    <div class="col-md-3 col-6">
                        <div class="counter">
                            <i class="fas fa-users"></i>
                            <div class="timer count-title count-number mt-3" data-to="15" data-speed="1500"></div>
                            <p class="count-text">Happy Customers</p>
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="counter">
                        <i class="fa-solid fa-people-roof"></i>
                            <div class="timer count-title count-number mt-3" data-to="20" data-speed="1500"></div>
                            <p class="count-text">Relationship Manager</p>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mt-md-0 mt-5">
                        <div class="counter">
                        <i class="fa-solid fa-building-columns"></i>
                            <div class="timer count-title count-number mt-3" data-to="10" data-speed="1500"></div>
                            <p class="count-text">Banks & NBFCs</p>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mt-md-0 mt-5">
                        <div class="counter">
                        <i class="fa-solid fa-business-time"></i>
                            <div class="timer count-title count-number mt-3" data-to="5" data-speed="1500"></div>
                            <p class="count-text">Business Conducted</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <section class="w3l-team py-5" id="team">
        <div class="container py-md-5 py-4">
            <div class="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4">
                <h3 class="title-style mb-2">Get Advice<font color="#990033"> From Expert</font> </h3>
                <p>Our communication, policies and processes are simple to understand and easy to follow</p>
            </div>
            <div class="row text-center">
                <div class="col-md-3 col-6">
                    <div class="team-grids text-center">
                        <img src="assets/images/team1.jpg" class="img-fluid " alt=""/>
                        <div class="team-info">
                            <div class="caption">
                                <div class="social-icons-section text-center">
                                    <a class="fac" href="#url">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a class="twitter" href="#url">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4>John Doe</h4>
                    <h6>Founder and CEO</h6>
                </div>
                <div class="col-md-3 col-6">
                    <div class="team-grids text-center">
                        <img src="assets/images/team3.jpg" class="img-fluid" alt=""/>
                        <div class="team-info">
                            <div class="caption">
                                <div class="social-icons-section text-center">
                                    <a class="fac" href="#url">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a class="twitter" href="#url">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4>Martin ker</h4>
                    <h6>Partner & co-founder</h6>
                </div>
                <div class="col-md-3 col-6 mt-md-0 mt-sm-5 mt-5">
                    <div class="team-grids text-center">
                        <img src="assets/images/team2.jpg" class="img-fluid" alt=""/>
                        <div class="team-info">
                            <div class="caption">
                                <div class="social-icons-section text-center">
                                    <a class="fac" href="#url">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a class="twitter" href="#url">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4> Alexander</h4>
                    <h6>Chairmen</h6>
                </div>
                <div class="col-md-3 col-6 mt-md-0 mt-sm-5 mt-5">
                    <div class="team-grids text-center">
                        <img src="assets/images/team4.jpg" class="img-fluid" alt=""/>
                        <div class="team-info">
                            <div class="caption">
                                <div class="social-icons-section text-center">
                                    <a class="fac" href="#url">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a class="twitter" href="#url">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4>Elizabeth </h4>
                    <h6>Manager</h6>
                </div>
            </div>
        </div>
    </section> */}
    <div class="bgm w3l-testimonials" id="testimonials">
    <section class=" pb-5" >
        <div class="container ">
            <div class="title-heading-w3 mx-auto text-center mb-5 pb-xl-4" >
                <br></br><br></br>
                <h3 class="title-style mb-2">Meet Our Happy Customers!</h3>
                <p>Moneylink brings joy to our customers plus provides loyalty perks. The testimonials below will tell you how!</p>
            </div>
            <div class="owl-testimonial owl-carousel owl-theme mx-auto" 
            
            >
                <div class="item">
                    <div class="slider-info">
                        <div class="img-circle">
                            <img src="assets/images/testi1.jpg" class="img-fluid rounded" alt="client image"/>
                        </div>
                        <div class="message-info">
                            <span class="fa fa-quote-left mr-2"></span>
                            <div class="message">Thank you so much for taking the time to send this! Everyone here at (business name) loves to know that our customers enjoy what we do.</div>
                            <div class="name">- Johnson</div>
                            <div class="desp"></div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="slider-info">
                        <div class="img-circle">
                            <img src="assets/images/testi2.jpg" class="img-fluid rounded" alt="client image"/>
                        </div>
                        <div class="message-info">
                            <span class="fa fa-quote-left mr-2"></span>
                            <div class="message">Providing goods and services isn’t all your business does; your real mission is to satisfy and delight your customers. That’s what makes them return again and again to purchase your products, use your services, and tell everyone they know about just how great your business is. t</div>
                            <div class="name">- Sami Wade</div>
                          
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="slider-info">
                        <div class="img-circle">
                            <img src="assets/images/testi3.jpg" class="img-fluid rounded" alt="client image"/>
                        </div>
                        <div class="message-info">
                            <span class="fa fa-quote-left mr-2"></span>
                            <div class="message">Providing goods and services isn’t all your business does; your real mission is to satisfy and delight your customers. That’s what makes them return again and again to purchase your products, use your services, and tell everyone they know about just how great your business is. </div>
                            <div class="name">- Smith roy</div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </div><br></br><br></br>
    </section></div><br></br><br></br><br></br><br></br><br></br><br></br>




    <div class="bg7" id="ok"><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
    </div>
    <Footer></Footer>
</>

  );
 
}

export default About;